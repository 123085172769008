import tableMutations from "../../templates/table/mutations";
import { SET_ITEMS } from "../../templates/table/mutation-types";
import { isNil } from "lodash";
import { setEntities } from "../../templates/entities";

export default {
  ...tableMutations,
  [SET_ITEMS](state, { ids, entities, meta }) {
    let keywordLocaleIds = [];

    ids.forEach(function(id) {
      const keyword = entities.entity[id];

      const mappedLocales = keyword.locales.map(function(localeId) {
        return entities.keywordLocale[localeId];
      });
      // mappedLocales.sort((a, b) =>
      //   a.searchCount > b.searchCount
      //     ? -1
      //     : b.searchCount > a.searchCount
      //     ? 1
      //     : 0
      // );
      mappedLocales.forEach(function(locale) {
        keywordLocaleIds.push(locale.id);
      });
    });
    entities.entity = entities.keywordLocale;

    if (!isNil(meta)) {
      Object.assign(state.pagination, {
        totalItems: meta.total,
        page: meta.current_page,
        rowsPerPage: parseInt(meta.per_page)
      });
    } else {
      // @TODO find good implementation for store that do client side filtering/pagination
      Object.assign(state.pagination, {
        totalItems: ids.length,
        page: 1,
        rowsPerPage: 20
      });
    }
    if (!isNil(entities.entity)) {
      setEntities(state, entities, true);
    }
    state.ids = keywordLocaleIds;
  }
};
